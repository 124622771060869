/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/bramble.guard.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

import { AppStoreUtils } from 'app/store/app.store';
import { ClearAllStoresAction } from 'app/store/app.store.constants';

export const withToken: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const token: string = localStorage.getItem('jwt-token');
  const store = inject(AppStoreUtils);
  const router = inject(Router);

  if (!token) {
    store.dispatch(new ClearAllStoresAction());
    return router.parseUrl('/login');
  } else {
    return true;
  }
};
