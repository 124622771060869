/* ==================================================================================================================
 * OpenGoSim Bluebell: main.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from 'app/app.config';
import { AppComponent } from 'app/app.component';

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
