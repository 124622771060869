/* ==================================================================================================================
 * OpenGoSim Bluebell: app/login/login.effects.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { debounceTime, delay, map, tap } from 'rxjs/operators';

import { WebSocketService } from 'app/shared/services/websockets.service';

import * as actions from '../actions';
import { AppStoreUtils } from '../app.store';
import { CognitoService } from 'app/shared/services/cognito.service';

@Injectable()
export class LoginEffects {

  
  refreshLogin$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.login.LOGIN_REFRESH, actions.login.RENEW_TOKEN_FOR_401),
    tap(() => this.cognito.refresh())
  ), {dispatch: false});

  
  retryFailedAction$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.login.RENEW_TOKEN_FOR_401),
    delay(2000),
    map((action: any) => action.payload),
    tap((action: Action) => !!localStorage.getItem('jwt-token') ? this.store.dispatch(action) : null)
  ), {dispatch: false});

  
  refreshWebSocketsAfterTokenRefresh$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.login.LOGIN_SUCCESS),
    debounceTime(500),
    tap(() => this.webSocket.refresh())
  ), {dispatch: false});

  constructor(private actions$: Actions,
              private cognito: CognitoService,
              private store: AppStoreUtils,
              private webSocket: WebSocketService) { }
}
