/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/1d-plots/1d-plots.effects.ts
 * Copyright 2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of as observableOf, Observable } from 'rxjs';
import { catchError, switchMap, map, throttleTime } from 'rxjs/operators';

import { uuid4 } from 'app/app-utils';
import { NotificationAction } from 'app/shared/services/notifications.service';
import { RestService } from 'app/shared/services/rest.service';

import * as actions from '../actions';
import { ExternalDataFile, OneDPlotConfig, Plot, Run, externalDataFilesUrl, runsUrl } from '../interfaces';
import { AppStoreUtils } from '../app.store';

@Injectable()
export class OneDPlotEffects {

  // CRUD
  
  addPlot$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.oneDPlots.PLOT_ADD),
    map((action: any) => action.payload),
    map((plot: Plot) => {
      const plots: Plot[] = this.store.getValueOnce(this.store.plots1D);
      return [...plots, Object.assign({id: uuid4()}, plot)];
    }),
    map((plots: Plot[]) => new actions.oneDPlots.SavePlots(plots))
  ));

  
  editPlot$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.oneDPlots.PLOT_EDIT),
    map((action: any) => action.payload),
    map((plot: Plot) => {
      const plots: Plot[] = this.store.getValueOnce(this.store.plots1D);
      return plots.map((p: Plot) => p.id === plot.id ? plot : p);
    }),
    map((plots: Plot[]) => new actions.oneDPlots.SavePlots(plots))
  ));

  
  removePlot$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.oneDPlots.PLOT_REMOVE),
    map((action: any) => action.payload),
    map((plot: Plot) => {
      const plots: Plot[] = this.store.getValueOnce(this.store.plots1D);
      return plots.filter((p: Plot) => p.id !== plot.id);
    }),
    map((plots: Plot[]) => new actions.oneDPlots.SavePlots(plots))
  ));

  
  postCompareRunsToBackend$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.oneDPlots.SAVE_COMPARE_RUNS),
    map((action: any) => action.payload),
    // .do((uuids: string[]) => this.store.dispatch(new actions.oneDPlots.StoreCompareRuns(uuids)))
    map((uuids: string[]) => {
      const config: OneDPlotConfig = this.store.getValueOnce(this.store.plots1DConfig);
      return Object.assign({}, config, {compare: [...uuids]});
    }),
    map((config: OneDPlotConfig) => {
      const selectedRun: Run = this.store.getValueOnce(this.store.selectedRun);
      return [config, selectedRun];
    }),
    switchMap(([config, run]: [OneDPlotConfig, Run]) =>
      this.rest.post(runsUrl + run.uuid + '/plots/', config).pipe(
        map((response: Run) => new actions.runs.LoadOneQuietlySuccess(response)),
        catchError((err: HttpErrorResponse) => {
          console.log('SAVE_PLOTS error', err);
          return observableOf(new NotificationAction({
            title: 'Save Plots Failed',
            body: 'An error occurred when communicating with the backend'
          }));
        })
      )
    )
  ));

  
  postPlotsToBackend$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.oneDPlots.SAVE_PLOTS),
    map((action: any) => action.payload),
    map((plots: Plot[]) => {
      const config: OneDPlotConfig = this.store.getValueOnce(this.store.plots1DConfig);
      return Object.assign({}, config, {plots: [...plots]});
    }),
    map((config: OneDPlotConfig) => {
      const selectedRun: Run = this.store.getValueOnce(this.store.selectedRun);
      return [config, selectedRun];
    }),
    switchMap(([config, run]: [OneDPlotConfig, Run]) =>
      this.rest.post(runsUrl + run.uuid + '/plots/', config).pipe(
        map((response: Run) => new actions.runs.LoadOneQuietlySuccess(response)),
        catchError((err: HttpErrorResponse) => {
          console.log('SAVE_PLOTS error', err);
          return observableOf(new NotificationAction({
            title: 'Save Plots Failed',
            body: 'An error occurred when communicating with the backend'
          }));
        })
      )
    )
  ));

  
  loadExternalDataFiles$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.oneDPlots.LOAD_EXTERNAL_DATA_FILES),
    throttleTime(1000),
    map((action: any) => action.payload),
    switchMap((subProject: string) =>
      this.rest.get(externalDataFilesUrl, {sub_project: subProject}).pipe(
        map((response: ExternalDataFile[]) => new actions.oneDPlots.LoadExternalDataFilesSuccess(response)),
        catchError((response: HttpErrorResponse) =>
          response.status === 401 ?
            observableOf(new actions.login.RenewTokenFor401(new actions.oneDPlots.LoadExternalDataFiles(subProject)))
            :
            observableOf(new actions.oneDPlots.LoadExternalDataFilesFail(response))
        )
      )
    )
  ));

  constructor(private actions$: Actions,
              private rest: RestService,
              private store: AppStoreUtils) { }
}
