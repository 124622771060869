/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/projects/projects.effects.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of as observableOf, Observable } from 'rxjs';
import { throttleTime, map, switchMap, catchError, tap } from 'rxjs/operators';

import { NotificationAction } from 'app/shared/services/notifications.service';
import { RestService } from 'app/shared/services/rest.service';

import * as actions from '../actions';
import { Project, projectsUrl } from '../interfaces';
import { AppStoreUtils } from '../app.store';
import { Router } from '@angular/router';

@Injectable()
export class ProjectsEffects {

  // CRUD
   createProject$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.projects.CREATE_PROJECT),
    map((action: any) => action.payload),
    switchMap((project: Project) =>
      this.rest.post(projectsUrl, project).pipe(
        map(response => new actions.projects.CreateSuccess(response)),
        catchError((err: HttpErrorResponse) => {
          console.log('CREATE_PROJECT error', err);
          return observableOf(new NotificationAction({
            title: 'Create Project Failed',
            body: 'Failed to create: ' + project.name})
          );
        })
      )
    )
  ));

  
  deleteProject$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.projects.DELETE_PROJECT),
    map((action: any) => action.payload),
    switchMap((project: Project) =>
      this.rest.del(projectsUrl + project.uuid + '/').pipe(
        map(() => new actions.projects.DeleteSuccess(project)),
        catchError(() =>
          observableOf(new NotificationAction({
            title: 'Delete Project Failed',
            body: 'Failed to delete: ' + project.name
          }))
        )
      )
    )
  ));

  
  loadProjects$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.projects.LOAD_PROJECTS),
    throttleTime(1000),
    tap(() => this.store.dispatch(new actions.projects.LoadStarted())),
    switchMap(() => this.rest.get(projectsUrl)
      .pipe(
        map(response => new actions.projects.LoadSuccess(response)),
        catchError((response: HttpErrorResponse) =>
          response.status === 401 ?
            observableOf(new actions.login.RenewTokenFor401(new actions.projects.Load()))
            :
            observableOf(new actions.projects.LoadFail(response))
        )
      )
    )
  ));

  
  updateProject$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.projects.UPDATE_PROJECT),
    map((action: any) => action.payload),
    switchMap((project: Project) =>
      this.rest.patch(projectsUrl + project.uuid + '/', project).pipe(
        map((response: Project) => new actions.projects.UpdateSuccess(response)),
        catchError((err: HttpErrorResponse) => {
          console.log('UPDATE_PROJECT error', err);
          return observableOf(new NotificationAction({
              title: 'Update Project Failed',
              body: 'Failed to update: ' + project.name
            })
          );
        })
      )
    )
  ));

  
  selectProjectAfterCreation$ = createEffect(() => this.actions$.pipe(
    ofType(actions.projects.CREATE_PROJECT_SUCCESS),
    map((action: any) => action.payload),
    tap((project: Project) => this.router.navigate(['/projects', project.uuid]))
  ), {dispatch: false});

  constructor(private actions$: Actions,
              private rest: RestService,
              private router: Router,
              private store: AppStoreUtils) { }
}
