/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/general-files-properties/generla-files-properties.effects.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of as observableOf, Observable } from 'rxjs';
import { map, tap, catchError, switchMap } from 'rxjs/operators';

import { NotificationAction } from 'app/shared/services/notifications.service';
import { RestService } from 'app/shared/services/rest.service';

import * as actions from '../actions';
import { GeneralFile, generalFileUrl } from '../interfaces';
import { AppStoreUtils } from '../app.store';

@Injectable()
export class GeneralFilesPropertiesEffects {

  // CRUD
  
  deleteGeneralFilesPropertyFile$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.generalFilesProps.DELETE_GENERAL_FILE),
    map((action: any) => action.payload),
    switchMap((generalFilesProperty: GeneralFile) =>
      this.rest.del(generalFileUrl + generalFilesProperty.uuid + '/').pipe(
        map(() => new actions.generalFilesProps.DeleteFileSuccess(generalFilesProperty)),
        catchError(() => observableOf(new NotificationAction({
          title: 'Delete General Files Property Failed',
          body: 'Failed to delete: ' + generalFilesProperty.name
        })))
      )
    )
  ));

  
  loadGeneralFilesPropertyFiles$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.generalFilesProps.LOAD_GENERAL_FILES),
    tap(() => this.store.dispatch(new actions.generalFilesProps.LoadSetTag())),
    map((action: any) => action.payload),
    switchMap((subProject: string) =>
      this.rest.get(generalFileUrl, {sub_project: subProject}).pipe(
        map((response: GeneralFile[]) => new actions.generalFilesProps.LoadSuccess(response)),
        catchError((err: HttpErrorResponse) =>
          err.status === 401 ?
            observableOf(new actions.login.RenewTokenFor401(new actions.generalFilesProps.Load(subProject)))
            :
            observableOf(new actions.generalFilesProps.LoadFail(err))
        )
      )
    )
  ));

  
  updateGeneralFilesPropertyFile$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.generalFilesProps.UPDATE_GENERAL_FILE),
    map((action: any) => action.payload),
    switchMap((generalFilesProperty: GeneralFile) =>
      this.rest.patch(generalFileUrl + generalFilesProperty.uuid + '/', generalFilesProperty).pipe(
        map((response: GeneralFile) => new actions.generalFilesProps.UpdateSuccess(response)),
        catchError((err: HttpErrorResponse) => {
          console.log('UPDATE_GENERAL_FILE error', err);
          return observableOf(new NotificationAction({
              title: 'Update General Files Property Failed',
              body: 'Failed to update ' + generalFilesProperty.name
            })
          );
        })
      )
    )
  ));

  
  projectDeleteTriggersRefresh$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.subProjects.DELETE_SUBPROJECT_SUCCESS),
    map(() => new actions.generalFilesProps.Refresh())
  ));

  constructor(private actions$: Actions,
              private rest: RestService,
              private store: AppStoreUtils) { }
}
