/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/account/account.effects.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of as observableOf, Observable } from 'rxjs';
import { catchError, map, switchMap, throttleTime } from 'rxjs/operators';

import { NotificationAction } from 'app/shared/services/notifications.service';
import { RestService } from 'app/shared/services/rest.service';

import * as actions from '../actions';
import { AppAccount, Transaction, accountUrl, balanceUrl, transactionsUrl, Balance } from '../interfaces';

@Injectable()
export class AccountEffects {

  
  getAccountDetails$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.account.GET_ACCOUNT_DETAILS),
    throttleTime(1000),
    switchMap(() =>
      this.rest.get(accountUrl).pipe(
        map((response: AppAccount) => new actions.account.GetAccountSuccess(response)),
        catchError((response: HttpErrorResponse) =>
          response.status === 401 ?
            observableOf(new actions.login.RenewTokenFor401(new actions.account.GetAccount))
            :
            observableOf(new actions.account.GetAccountFail(response))
        )
      )
    )
  ));

  
  getAccountFail$ = createEffect(() => this.actions$.pipe(
    ofType(actions.account.GET_ACCOUNT_DETAILS_FAIL),
    map((action: any) => action.payload),
    map(response => new NotificationAction({
      title: 'Get Account Details Failed',
      body: !!response.error.detail ? response.error.detail : 'Failed to fetch details'
    }))
  ));

  
  getBalance$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.account.GET_BALANCE),
    throttleTime(1000),
    switchMap(() =>
      this.rest.get(balanceUrl).pipe(
        map((response: Balance) => new actions.account.GetBalanceSuccess(response)),
        catchError((response: HttpErrorResponse) =>
          response.status === 401 ?
            observableOf(new actions.login.RenewTokenFor401(new actions.account.GetBalance()))
            :
            observableOf(new actions.account.GetAccountFail(response))
        )
      )
    )
  ));

  
  getTransactions$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.account.GET_TRANSACTIONS),
    throttleTime(1000),
    switchMap(() =>
      this.rest.get(transactionsUrl).pipe(
        map((response: Transaction[]) => new actions.account.GetTransactionsSuccess(response)),
        catchError((err: HttpErrorResponse) => {
          console.log('GET_TRANSACTIONS error', err);
          return observableOf(new NotificationAction({
              title: 'Get Transactions Failed',
              body: !!err.error.detail ? err.error.detail : 'Unknown failure'
            })
          );
        })
      )
    )
  ));

  constructor(private actions$: Actions, private rest: RestService) { }
}
