import { Routes } from '@angular/router';
import { withToken } from 'app/bramble/bramble.guard';


export const routes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('app/login/login.component').then(m => m.LoginComponent),
  },
  {
    path: '',
    loadComponent: () => import('app/bramble/bramble.component').then(m => m.BrambleComponent),
    canActivate: [withToken],
    canActivateChild: [withToken],
    children: [
      // Effective Homepage
      {
        path: '',
        redirectTo: 'projects',
        pathMatch: 'full'
      },

      {
        path: 'projects',
        loadComponent: () => import('app/bramble/projects/projects.component').then(m => m.ProjectsComponent),
      },
      {
        path: 'projects/:uuid',
        loadComponent: () => import('app/bramble/sub-projects/sub-projects.component').then(m => m.SubProjectsComponent),
      },
      {
        path: 'sub-projects/:uuid/fluid-properties',
        loadComponent: () => import('app/bramble/fluid-properties/fluid-properties.component').then(m => m.FluidPropertiesComponent),
      },
      {
        path: 'sub-projects/:uuid/grids',
        loadComponent: () => import('app/bramble/grids/grids.component').then(m => m.GridsComponent),
      },
      {
        path: 'sub-projects/:uuid/general-files-properties',
        loadComponent: () => import('app/bramble/general-files-properties/general-files-properties.component').then(m => m.GeneralFilesPropertiesComponent),
      },
      {
        path: 'sub-projects/:uuid/material-properties',
        loadComponent: () => import('app/bramble/material-properties/material-properties.component').then(m => m.MaterialPropertiesComponent),
      },
      {
        path: 'sub-projects/:uuid',
        loadComponent: () => import('app/bramble/runs/runs.component').then(m => m.RunsComponent),
      },
      {
        path: 'runs/:uuid',
        loadComponent: () => import('app/bramble/simulation-control/simulation-control.component').then(m => m.SimulationControlComponent),
      },
      {
        path: 'runs/:uuid/1d',
        loadComponent: () => import('app/bramble/1d-plots/1d-plots.component').then(m => m.OneDPlotsComponent),
      },
      {
        path: 'support',
        loadComponent: () => import('app/bramble/support/support.component').then(m => m.SupportComponent),
      },

      {
        path: 'account',
        loadComponent: () => import('app/bramble/account/account.component').then(m => m.AccountComponent),
      },
      {
        path: 'queue',
        loadComponent: () => import('app/bramble/queue/queue.component').then(m => m.QueueComponent),
      },
    ]
  }
];
