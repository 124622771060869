/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/sub-projects/sub-sub-projects.effects.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of as observableOf, Observable } from 'rxjs';
import { tap, throttleTime, switchMap, map, catchError } from 'rxjs/operators';

import { NotificationAction } from 'app/shared/services/notifications.service';
import { RestService } from 'app/shared/services/rest.service';

import * as actions from '../actions';
import { SubProject, subProjectsUrl } from '../interfaces';
import { AppStoreUtils } from '../app.store';
import { Router } from '@angular/router';

@Injectable()
export class SubProjectsEffects {

  // CRUD
  
  createSubProject$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.subProjects.CREATE_SUBPROJECT),
    map((action: any) => action.payload),
    switchMap((subProject: SubProject) =>
      this.rest.post(subProjectsUrl, subProject).pipe(
        map((response: SubProject) => new actions.subProjects.CreateSuccess(response)),
        catchError((err: HttpErrorResponse) => {
          console.log('CREATE_SUBPROJECT error', err);
          return observableOf(new NotificationAction({
              title: 'Create Sub-Project Failed',
              body: 'Failed to create ' + subProject.name
            })
          );
        })
      )
    )
  ));

  
  deleteSubProject$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.subProjects.DELETE_SUBPROJECT),
    map((action: any) => action.payload),
    switchMap((subProject: SubProject) =>
      this.rest.del(subProjectsUrl + subProject.uuid + '/').pipe(
        map(() => new actions.subProjects.DeleteSuccess(subProject)),
        catchError(() => observableOf(new NotificationAction({
          title: 'Delete Sub-Project Failed',
          body: 'Failed to delete: ' + subProject.name
        })))
      )
    )
  ));

  
  loadSubProjects$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.subProjects.LOAD_SUBPROJECTS),
    throttleTime(1000),
    tap(() => this.store.dispatch(new actions.subProjects.LoadStarted())),
    switchMap(() =>
      this.rest.get(subProjectsUrl).pipe(
        map((response: SubProject[]) => new actions.subProjects.LoadSuccess(response)),
        catchError((err: HttpErrorResponse) =>
          err.status === 401 ?
            observableOf(new actions.login.RenewTokenFor401(new actions.subProjects.Load()))
            :
            observableOf(new actions.subProjects.LoadFail(err))
        )
      )
    )
  ));

  
  updateSubProject$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.subProjects.UPDATE_SUBPROJECT),
    map((action: any) => action.payload),
    switchMap((subProject: SubProject) =>
      this.rest.patch(subProjectsUrl + subProject.uuid + '/', subProject).pipe(
        map((response: SubProject) => new actions.subProjects.UpdateSuccess(response)),
        catchError((err: HttpErrorResponse) => {
          console.log('UPDATE_SUBPROJECT error', err);
          return observableOf(new NotificationAction({
              title: 'Update Sub-Project Failed',
              body: 'Failed to update ' + subProject.name
            })
          );
        })
      )
    )
  ));

  
  subProjectDeleteTriggersRefresh$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.projects.DELETE_PROJECT_SUCCESS),
    map(() => new actions.subProjects.Refresh())
  ));

  
  selectSubProjectAfterCreation$ = createEffect(() => this.actions$.pipe(
    ofType(actions.subProjects.CREATE_SUBPROJECT_SUCCESS),
    map((action: any) => action.payload),
    tap((subProject: SubProject) => this.router.navigate(['/sub-projects', subProject.uuid]))
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private rest: RestService,
    private router: Router,
    private store: AppStoreUtils
  ) { }
}
