/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/fluid-properties/fluid-properties.effects.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of as observableOf, Observable } from 'rxjs';
import { map, throttleTime, tap, catchError, switchMap } from 'rxjs/operators';

import { NotificationAction } from 'app/shared/services/notifications.service';
import { RestService } from 'app/shared/services/rest.service';

import * as actions from '../actions';
import { FluidProperty, fluidPropertyUrl } from '../interfaces';
import { AppStoreUtils } from '../app.store';

@Injectable()
export class FluidPropertiesEffects {

  // CRUD
  
  deleteFluidPropertyFile$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.fluidProps.DELETE_FLUID_FILE),
    map((action: any) => action.payload),
    switchMap((fluidProperty: FluidProperty) =>
      this.rest.del(fluidPropertyUrl + fluidProperty.uuid + '/').pipe(
        map(() => new actions.fluidProps.DeleteFileSuccess(fluidProperty)),
        catchError(() => observableOf(new NotificationAction({
          title: 'Delete Fluid Property Failed',
          body: 'Failed to delete: ' + fluidProperty.name
        })))
      )
    )
  ));

  
  loadFluidPropertyFiles$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.fluidProps.LOAD_FLUID_FILES),
    tap(() => this.store.dispatch(new actions.fluidProps.LoadSetTag())),
    map((action: any) => action.payload),
    switchMap((subProject: string) =>
      this.rest.get(fluidPropertyUrl, {sub_project: subProject}).pipe(
        map((response: FluidProperty[]) => new actions.fluidProps.LoadSuccess(response)),
        catchError((err: HttpErrorResponse) =>
          err.status === 401 ?
            observableOf(new actions.login.RenewTokenFor401(new actions.fluidProps.Load(subProject)))
            :
            observableOf(new actions.fluidProps.LoadFail(err))
        )
      )
    )
  ));

  
  updateFluidPropertyFile$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.fluidProps.UPDATE_FLUID_FILE),
    map((action: any) => action.payload),
    switchMap((fluidProperty: FluidProperty) =>
      this.rest.patch(fluidPropertyUrl + fluidProperty.uuid + '/', fluidProperty).pipe(
        map((response: FluidProperty) => new actions.fluidProps.UpdateSuccess(response)),
        catchError((err: HttpErrorResponse) => {
          console.log('UPDATE_FLUID_FILE error', err);
          return observableOf(new NotificationAction({
              title: 'Update Fluid Property Failed',
              body: 'Failed to update ' + fluidProperty.name
            })
          );
        })
      )
    )
  ));

  
  projectDeleteTriggersRefresh$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.subProjects.DELETE_SUBPROJECT_SUCCESS),
    map(() => new actions.fluidProps.Refresh())
  ));

  constructor(private actions$: Actions,
              private rest: RestService,
              private store: AppStoreUtils) { }
}
