/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/grids/grids.effects.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of as observableOf, Observable } from 'rxjs';
import { map, throttleTime, tap, catchError, switchMap } from 'rxjs/operators';

import { NotificationAction } from 'app/shared/services/notifications.service';
import { RestService } from 'app/shared/services/rest.service';

import * as actions from '../actions';
import { Grid, gridUrl } from '../interfaces';
import { AppStoreUtils } from '../app.store';

@Injectable()
export class GridsEffects {

  // CRUD
  
  deleteGridFile$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.grids.DELETE_GRID_FILE),
    map((action: any) => action.payload),
    switchMap((grid: Grid) =>
      this.rest.del(gridUrl + grid.uuid + '/').pipe(
        map(() => new actions.grids.DeleteFileSuccess(grid)),
        catchError(() => observableOf(new NotificationAction({
          title: 'Delete Grid Failed',
          body: 'Failed to delete: ' + grid.name
        })))
      )
    )
  ));

  
  loadGridFiles$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.grids.LOAD_GRID_FILES),
    tap(() => this.store.dispatch(new actions.grids.LoadSetTag())),
    map((action: any) => action.payload),
    switchMap((subProject: string) =>
      this.rest.get(gridUrl, {sub_project: subProject}).pipe(
        map((response: Grid[]) => new actions.grids.LoadSuccess(response)),
        catchError((response: HttpErrorResponse) =>
          response.status === 401 ?
            observableOf(new actions.login.RenewTokenFor401(new actions.grids.Load(subProject)))
            :
            observableOf(new actions.grids.LoadFail(response))
        )
      )
    )
  ));

  
  updateGridFile$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.grids.UPDATE_GRID_FILE),
    map((action: any) => action.payload),
    switchMap((grid: Grid) =>
      this.rest.patch(gridUrl + grid.uuid + '/', grid).pipe(
        map((response: Grid) => new actions.grids.UpdateSuccess(response)),
        catchError((err: HttpErrorResponse) => {
          console.log('UPDATE_GRID_FILE error', err);
          return observableOf(new NotificationAction({
              title: 'Update Grid Failed',
              body: 'Failed to update ' + grid.name
            })
          );
        })
      )
    )
  ));

  // If project is deleted, remove all associated actions.grids
  
  deleteProjectTriggersRefresh: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.subProjects.DELETE_SUBPROJECT_SUCCESS),
    map(() => new actions.grids.Refresh())
  ));

  constructor(private actions$: Actions,
              private rest: RestService,
              private store: AppStoreUtils) { }
}
