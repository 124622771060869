import { isDevMode } from '@angular/core';
import { provideHttpClient } from '@angular/common/http';
import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';

import { routes } from './app.routes';
import * as reducers from 'app/store/reducers';
import {
  OneDPlotEffects,
  AccountEffects,
  ComputeResourcesEffects,
  FluidPropertiesEffects,
  GeneralFilesPropertiesEffects,
  GridsEffects,
  LoginEffects,
  MaterialPropertiesEffects,
  ProjectsEffects,
  RunsEffects,
  SubProjectsEffects
} from 'app/store/effects';
import { metaReducers } from 'app/store/app.store';


export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(),
    provideAnimations(),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideStore(
      undefined,
      { metaReducers }
    ),
    provideState({ name: 'account', reducer: reducers.account.reducer }),
    provideState({ name: 'computeResources', reducer: reducers.computeResources.reducer }),
    provideState({ name: 'fluidProperties', reducer: reducers.fluidProps.reducer }),
    provideState({ name: 'generalFilesProperties', reducer: reducers.generalFilesProps.reducer }),
    provideState({ name: 'grids', reducer: reducers.grids.reducer }),
    provideState({ name: 'login', reducer: reducers.login.reducer }),
    provideState({ name: 'materialProperties', reducer: reducers.materialProps.reducer }),
    provideState({ name: 'plots1D', reducer: reducers.oneDPlots.reducer }),
    provideState({ name: 'projects', reducer: reducers.projects.reducer }),
    provideState({ name: 'subProjects', reducer: reducers.subProjects.reducer }),
    provideState({ name: 'runs', reducer: reducers.runs.reducer }),
    provideEffects(
      OneDPlotEffects,
      AccountEffects,
      ComputeResourcesEffects,
      FluidPropertiesEffects,
      GeneralFilesPropertiesEffects,
      GridsEffects,
      LoginEffects,
      MaterialPropertiesEffects,
      ProjectsEffects,
      RunsEffects,
      SubProjectsEffects,
    ),
    provideStoreDevtools({
      // Retains last 25 states
      maxAge: 25,
      // Restrict extension to log-only mode
      logOnly: !isDevMode(),
      // Pauses recording actions and state changes when the extension window is not open
      autoPause: true,
      //  If set to true, will include stack trace for every dispatched action,
      //  so you can see it in trace tab jumping directly to that part of code
      trace: false,
      // maximum stack trace frames to be stored (in case trace option was provided as true)
      traceLimit: 75,
      // If set to true, the connection is established within the Angular zone
      connectInZone: true,
    })
  ]
};
