/* ==================================================================================================================
 * OpenGoSim Bluebell: app/app.component.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {

  ngOnInit(): void {
    // hide preLoader when app has loaded
    document.getElementById('preLoader').style.display = 'none';
  }

}
