/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/material-properties/material-properties.effects.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of as observableOf, Observable } from 'rxjs';
import { map, tap, catchError, switchMap } from 'rxjs/operators';

import { NotificationAction } from 'app/shared/services/notifications.service';
import { RestService } from 'app/shared/services/rest.service';

import * as actions from '../actions';
import { MaterialProperty, materialPropertyUrl } from '../interfaces';
import { AppStoreUtils } from '../app.store';

@Injectable()
export class MaterialPropertiesEffects {

  // CRUD
  
  deleteMaterialPropertyFile$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.materialProps.DELETE_MATERIAL_FILE),
    map((action: any) => action.payload),
    switchMap((materialProperty: MaterialProperty) =>
      this.rest.del(materialPropertyUrl + materialProperty.uuid + '/').pipe(
        map(response => new actions.materialProps.DeleteFileSuccess(materialProperty)),
        catchError(() => observableOf(new NotificationAction({
          title: 'Delete Material Property Failed',
          body: 'Failed to delete: ' + materialProperty.name
        })))
      )
    )
  ));

  
  loadMaterialPropertyFiles$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.materialProps.LOAD_MATERIAL_FILES),
    tap(() => this.store.dispatch(new actions.materialProps.LoadSetTag())),
    map((action: any) => action.payload),
    switchMap((subProject: string) =>
      this.rest.get(materialPropertyUrl, {sub_project: subProject}).pipe(
        map((response: MaterialProperty[]) => new actions.materialProps.LoadSuccess(response)),
        catchError((response: HttpErrorResponse) =>
          response.status === 401 ?
            observableOf(new actions.login.RenewTokenFor401(new actions.materialProps.Load(subProject)))
            :
            observableOf(new actions.materialProps.LoadFail(response))
        )
      )
    )
  ));

  
  updateMaterialPropertyFile$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.materialProps.UPDATE_MATERIAL_FILE),
    map((action: any) => action.payload),
    switchMap((materialProperty: MaterialProperty) =>
      this.rest.patch(materialPropertyUrl + materialProperty.uuid + '/', materialProperty).pipe(
        map((response: MaterialProperty) => new actions.materialProps.UpdateSuccess(response)),
        catchError((err: HttpErrorResponse) => {
          console.log('UPDATE_MATERIAL_FILE error', err);
          return observableOf(new NotificationAction({
              title: 'Update Material Property Failed',
              body: 'Failed to update ' + materialProperty.name
            })
          );
        })
      )
    )
  ));

  
  projectDeleteTriggersRefresh$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.subProjects.DELETE_SUBPROJECT_SUCCESS),
    map(() => new actions.materialProps.Refresh())
  ));

  constructor(private actions$: Actions,
              private rest: RestService,
              private store: AppStoreUtils) { }
}
