/* ==================================================================================================================
 * OpenGoSim Bluebell: app/store/effects/queue.effects.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of as observableOf, Observable } from 'rxjs';
import { catchError, map, switchMap, throttleTime } from 'rxjs/operators';

import { NotificationAction } from 'app/shared/services/notifications.service';
import { RestService } from 'app/shared/services/rest.service';

import * as actions from '../actions';
import {
  QueueItem,
  queueUrl,
  testMachineStartUrl,
  testMachineStateUrl,
  testMachineTerminateUrl
} from '../interfaces';

@Injectable()
export class ComputeResourcesEffects {

  
  getTestMachineState$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.computeResources.GET_TEST_MACHINE_STATE),
    throttleTime(1000),
    switchMap(() =>
      this.rest.get(testMachineStateUrl).pipe(
        map(response =>
          new actions.computeResources.GetTestMachineStateSuccess(Object.assign({
            cores: response.cores,
            output_name: response.output_name,
            instanceType: response.instance_type,
            status: response.status
          }))
        ),
        catchError((response: HttpErrorResponse) =>
          response.status === 401 ?
            observableOf(new actions.login.RenewTokenFor401(new actions.computeResources.GetTestMachineState))
            :
            observableOf(new actions.computeResources.GetTestMachineStateFail(response))
        )
      )
    )
  ));

  
  startTestMachine$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.computeResources.START_TEST_MACHINE),
    throttleTime(3000),
    map((action: any) => action.payload),
    switchMap((data: {size: string, duration: number}) =>
      this.rest.get(testMachineStartUrl, {size: data.size, duration: data.duration}).pipe(
        map(response => {
          return new actions.computeResources.GetTestMachineStateSuccess(Object.assign({
            status: response.status,
            instanceType: response.instance_type
          }));
        }),
        catchError((err: HttpErrorResponse) => {
          console.log('START_TEST_MACHINE error', err);
          return observableOf(new NotificationAction({
              title: 'Test Machine Start Failed',
              body: !!err.error.detail ? err.error.detail : 'Failed to start Test Machine'
            })
          );
        })
      )
    )
  ));

  
  terminateTestMachine$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.computeResources.TERMINATE_TEST_MACHINE),
    throttleTime(3000),
    switchMap(() =>
      this.rest.get(testMachineTerminateUrl).pipe(
        map(response => {
          return new actions.computeResources.GetTestMachineStateSuccess(Object.assign({
            status: response.status,
            instanceType: response.instance_type
          }));
        }),
        catchError((err: HttpErrorResponse) => {
          console.log('TERMINATE_TEST_MACHINE error', err);
          return observableOf(new NotificationAction({
              title: 'Test Machine Start Failed',
              body: 'Failed to start Test Machine'
            })
          );
        })
      )
    )
  ));

  
  getQueue$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.computeResources.GET_QUEUE),
    switchMap(() =>
      this.rest.get(queueUrl).pipe(
        map((response: QueueItem[]) => new actions.computeResources.GetQueueSuccess(response)),
        catchError((err: HttpErrorResponse) => {
          console.log('GET_QUEUE error', err);
          return observableOf(new NotificationAction({
              title: 'Get Queue Failed',
              body: 'Failed to fetch queue'
            })
          );
        })
      )
    )
  ));

  constructor(private actions$: Actions, private rest: RestService) { }
}
